.btn {
    font: inherit;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    border-radius: 4px;
    background-color: #540a78;
    color: white;
    border: 1px solid #540a78;
    margin: 0 1rem;
    margin-top: 10pt;
    margin-left: 2rem;
    height: 3.5rem;
    width: 15rem;
  }
  
  .btn:hover {
    background-color: #9c1458;
    border-color: #883860;
  }
  
  .btn--alt {
    background-color: transparent;
    color: #8b3a63;
  }
  
  .btn--alt:hover {
    background-color: #f8dae9;
  }

.center{
    text-align: center;
}