.transcriptionGrid {
    height: 100%;
}

.transcriptionWorkContainer {
    position: relative;
    display: block;
}

.transcriptionSettingsGrid {
    height: 100%;
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 20px !important;
}