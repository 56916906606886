
.center{
    text-align: center;
}

.h4{
    margin-bottom: 0.5rem;
    text-align: center;
}

.metadataInputField{
    margin-inline-end: 30px !important; 
    writing-mode: horizontal-tb;
}

.metadataInputRadioButton{
    color: #714c83 !important;
}

