.h3,
.h4 {
    text-align: center;

}

.h3 {
    margin-bottom: 25px;
}

.h4 {
    margin-top: 15px;
}

.mainSettingsContainer {
    align-self: center;
}

.settingsContainer {
    border: 1px;
    border-color: gray;
    border-style: solid;
}

.settingContainer {
    margin-top: 12px !important;
    flex-direction: row !important;
    -webkit-box-align: center !important;
    align-items: center !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
}

.settingLabel {
    margin-right: 50px !important;
    color: black !important;

}

.listenButton {
    margin-left: 40px !important;
    background-color: rgba(159, 2, 170, 0.3) !important;
    color: black !important;
}

.settingRadioGroup {
    justify-content: center;
}

.settingRadioButton {
    color: #714c83 !important;
}


.settingsConfirmContainer {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.confirmSettingsButtonDefault {
    background-color: #540a78 !important;
    color: white !important;
    width: 150px;
    border-radius: 0.5rem !important;
}

.confirmSettingsButtonUnlabeled {
    background-color: #b834c4 !important;
    color: white !important;
    width: 150px;
    border-radius: 5rem !important;
}