* {
    box-sizing:border-box;
    margin: 0;
    padding: 0;
  }

.card {
    position: relative;
    display: block;
    margin: 0 auto;
    margin-top: 5pt;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
    padding: 0.7rem;
    box-sizing: border-box;
    width: 99%;
    height: auto
  }