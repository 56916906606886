.textArea{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    resize: auto;
    width: 90%;
    color:black;
    font-size: 1rem;
}

.h3{
    text-align: center;
}