
  .btn {
    font: inherit;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    border-radius: 4px;
    background-color: #540a78;
    color: white;
    border: 1px solid #800040;
    margin: 0 1rem;
    margin-top: 1rem;
  }
  
  .btn:hover {
    background-color: #9c1458;
    border-color: #9c1458;
  }
  
  .btnalt {
    background-color: transparent;
    color: #800040;
  }
  
  .btnalt:hover {
    background-color: #f8dae9;
  } 
  .modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: white;
    border: 1px solid #000000;
    padding: 1rem;
    text-align: center;
    width: 30rem;
    z-index: 10;
    position: fixed;
    top: 20vh;
    left: calc(50% - 15rem);
  }
  